body {
    font-family: Helvetica,Tahoma;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  
  .scroll-counter {
    position: fixed;
    top: 0;
    right: 0;
    padding: 15px;
    color: white;
    background-color: rgba(0,0,0,0.4);
  }
  
  .aos-all {
    width: 1000px;
    max-width: 98%;
    margin: 50px auto;
  }
  
  .aos-item {
    display: inline-block;
    float: left;
    width: 100%;
    height: 300px;
    padding: 20px;
  }
  
  .aos-item::before {
    content: attr(data-id);
    position: relative;
    width: 100%;
    height: 100%;
    float: left;
    background: #1da4e2;
    line-height: 260px;
    text-align: center;
    color: #fff;
  }
  
  @media screen and (min-width: 420px) {
    .aos-item {
      width: 50%;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .aos-item {
      width: 33%;
    }
  }
  
  .aos-anchors__lines {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 2px solid blue;
    border-bottom: 2px solid red;
  }
  
  .aos-anchors__lines::before {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: green;
  }
  
  .aos-anchors__sidebar {
    position: fixed;
    left: 0;
    top: 0;
  }
  
  .aos-anchors__sidebar > div {
    padding: 5px 10px;
    background: #f1f1f1;
  }
  
  .aos-anchors__content {
    width: 400px;
    margin: 50px auto;
  }
  
  .aos-anchors__content > div {
    position: relative;
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
    background: #f1f1f1;
    line-height: 300px;
    text-align: center;
    color: #333;
  }
  
  .aos-anchors__content > div::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
  }
  
  .aos-anchors__content > div::after {
    content: attr(data-anchor-id);
    position: relative;
    background-color: #f1f1f1;
  }
  
  .aos-anchors__content > div[data-placement$="-bottom"]::before {
    background-color: red;
  }
  
  .aos-anchors__content > div[data-placement$="-center"]::before {
    background-color: green;
  }
  
  .aos-anchors__content > div[data-placement$="-top"]::before {
    background-color: blue;
  }
  
  .aos-anchors__content > div[data-placement^="top-"]::before {
    top: 0;
  }
  
  .aos-anchors__content > div[data-placement^="center-"]::before {
    top: 0;
    bottom: 0;
    margin: auto;
  }
  
  .aos-anchors__content > div[data-placement^="bottom-"]::before {
    bottom: 0;
  }