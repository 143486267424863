
/******** THEME COLOR PALLETE */

/* Blue:    #1C3879
Vanilla: rgb(254, 250, 240); */


/**** FONT STYLES */

.subject, .profesion,
.me-container > .section-content-container p,
.me-container > .section-content-container h6,
.me-container > .section-content-container,
.hobbies-container .row .personality-quote,
.course-container,
.hospitality-container-wraper > p,
.contact-container h3{
    font-family: 'Sarabun', sans-serif;
}

.profesion,
.course-container,
.feedback-container p{
    font-weight: 600;
}

.junior{
    font-weight: 300;
}

.me-container > .section-content-container h6,
.hobbies-container .row .personality-quote,
.contact-container h3{
font-weight: 800;
}

/********* COLORS */ 
p, 
a, 
h6,
.hobbies-container,
.background-container, 
#contact-form .form-control,
.project-card,
.project-tech > .social-icon-wraper a,
.personality-quote,
.secondary-navbar-wraper .navbar-item-box p{
    color: #1C3879;

}

.contact-container h3{
    color: #001253;
 }

/*******************************  MODAL ***/
.modal-contact{
    display: none;
    background-color: #001253;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.close-container {
 color: #001253;
 text-align: end;
 padding-right: 5rem;
 position: relative;
 top: 1rem;    
 font-size: 1.2rem;
}


/****** CONTACT ICON */
#contact-icon-1{
    text-align: center;
}

#contact-icon-button{
    background-color: inherit;
    border: none;
    margin: 0;
    padding: 0;
}

#contact-icon-1 img{
    position: relative;
    width: 90%;
    height: 90%;
    animation-name: slideRight;
   animation-duration: 4s;
   transition-timing-function: ease-in ease-out;
   animation-iteration-count: infinite;
   }

   @keyframes slideRight {
       0% {left:-25px}
    50% {left:25px}
       100% {left:-25px}
   }


   #contact-icon-2 img{
    position: relative;
    width: 90%;
    height: 90%;
    animation-name: slideUp;
   animation-duration: 4.3s;
   transition-timing-function: ease-in ease-out;
   animation-iteration-count: infinite;
   }

   @keyframes slideUp {
       0% {bottom:-15px}
       50% {bottom:15px}
       100% {bottom:-15px}
   }


/******************************** ANIMATION CLASSES */
.line1-heading,
.line2-heading, 
.separator,
.profesion,
.subject,
.img-container {
    visibility: hidden;
}

.quote-wraper{
    display: none;
}

.social-wraper, #main-navbar, #contact-icon-1{
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.nav-hide{
  visibility:hidden;
}

.nav-show{
    display: grid;
}

.navbar-container{
    display: none;
}

.hiden{
    display: none;
}

.showBorder{

    border-bottom: solid 1px #1C3879;
}

.show{
    animation-name: animateSection;
    transition: ease-in-out;
    -webkit-transition: ease-in-out;
    -moz-transition: ease-in-out;
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    -moz-animation-duration: 2s;
}

@keyframes animateSection {
    from{
        display: block;
    }
    to {
        display: block;

    }
}

.hide-border{
    border: none;
    font-weight: bold;
}

.show-border{
    border-bottom: grid;
}

/********************************** CORE CSS*/
body {
    margin: 0;
    padding: 0;
    background-color: #1C3879;
}

.main-wrapper{
    width: 100%;
    margin: 0;
    min-height: 100vh;
}

#hero-wrapper{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 50vh;
    }

.heading-wraper{
    width: 100%;
    margin: 0 auto;
    justify-content: center;
}

.social-icon-wraper > a, 
.heading-wraper *{
    color:rgb(254, 250, 240);
}

.quote-wraper{
    width: 100%;
    margin: 0 auto;
}

/**************  MAIN NAVBAR ***/

.main-navbar-wraper{
    width: 100%;
    /* padding-top: 100%; */
    margin: 100% auto 0;
    position: absolute;
    left: 0;
    bottom: 0;
}

.navbar-container, .main-navbar-container{
    display: flex;
    flex-direction: row;
    padding: 3px 3px 0;
    width: 100%;
    margin: 0 auto;
    justify-content: space-evenly;
}

.main-navbar-container > .navbar-item-box{
    transition: border 0.5s ease-in-out;

}


.main-navbar-container > .navbar-item-box:hover{
border-color: #1C3879;
}

.navbar-item-box{
    cursor: pointer;
    width: 32%;
    text-align: center;
}

.navbar-item-box > a{
    padding: 0;
    margin: 0;
    text-decoration: none;
}


/**********  SECONDARY NAVBAR ***/

    .secondary-navbar-wraper{
        position: relative;
        width: 100%;
        padding: 0;
        margin: 1rem auto 7rem;
    }

    #secondary-navbar #secundary-navbar-container .row .navbar-item-box{
        text-align: center;
        width: 100%;

     }

     .secondary-navbar-wraper .navbar-container{
        padding-top: 0;
        padding-bottom: 3px;
     }

    /******************* SECTIONS */
    .section-wraper{
        position: relative;
        width: 100%;
        margin: 0 auto;
    }

    .section-container{
        position: absolute;
        top: 0;
        left: 0;
    background-color:rgb(254, 250, 240);
    }

    .section-content-container{
        width: 100%;
        padding: 0 1.7rem 2rem;
    }

    /********* ME SECTION */
    .me-container > .section-content-container h6{
        text-align: center;
        font-style: italic;
        letter-spacing: 1px;
    }

    .me-container > .section-content-container{
        margin: 0 auto;
        text-align: left;
  
    }

    .hobbies-container .row .personality-quote{
        font-style: italic;
        text-align: center;
        letter-spacing: 1px;
    }

    /********** PROJECTS SECTION */

    .project-img img {
        width: 100%;
        height: 100%;
    }

.project-tech {
    display: flex;
    justify-content: space-evenly;
  }
  
  .project-tech img {
     opacity: 0.7;
  }

  .project-separator{
    background-color: #E6CBA8;
    height: 2px;
    margin: 3rem auto auto;
    width: 30%;
}

  /*** BACKGROUND SECTION SECTION */

  .course-container{
    margin: 2.5rem auto 2.5rem;
    padding-right: 0;
}

.dots{
    margin-top: 6rem;
    margin-bottom: 5rem;
}

.dot{
    width: 3px;
    height: 3px;
    margin: auto 3px;
    background-color: #a3a3a3;
    border-radius: 50%;
}

ul li{
    list-style: none;
}

.course-container-wraper{
    border-left: solid 2px #E6CBA8;
 }
 
 .hospitality-container-wraper{
     border-right: solid 2px #E6CBA8;
     padding-right: 20px;
  }
 

/******** CONTACT FORM SECTION SECTION */

  .contact-container h3{
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 2px;
}

 .course-container ul li{
     line-height: 1.3rem;
 }

.contact-wraper{
    background-color:rgb(254, 250, 240);
}

 .contact-container h3{
    color: #001253;
 }

 #contact-form{
    display: flex;
    flex-direction: column;
}

#contact-form .form-control{
    background-color: inherit;
    border-color: #1C3879;
    letter-spacing: 1px;
    box-shadow: none;
}

#contact-form .form-control:focus{
    border: solid 2px;
}

 .form-control::placeholder{
    color: #1c387971;
    letter-spacing: 2px;
    font-style: italic;

}

#contact-form button{
    background-color: #001253;
    border-color: #001253;
    color: rgb(254, 250, 240);
    width: 100%;
}

#contact-form button:hover{
    background-color: rgb(254, 250, 240);
    border-color: #001253;
    color: #001253;
    font-weight: 600;
}

#close{
    cursor: pointer;
}

.feedback-container p{
    margin: 0;
}

/************* COLLAPSE*/
.card-body{
    background-color: rgb(254, 250, 240);
    padding: 0;
    margin-top: 1rem;
    border: none;
}

.collapsable .fa-github,
.project-tech-large .fa-github{
    color: #001253;
}


.collapsable a {
    color: #001253;
    font-weight: 600;
    text-decoration: none;
}


/************************************* MOBILE SCREEN */

@media only screen and (min-width: 320px) {
   
    .social-wraper{
        width: 100%;
        margin: 0 auto;
        padding-top: 11%;
        height: 18vh;

    }

    .social-container{
        display: flex;
        margin: 0 auto;
        flex-direction: row;
        width: 90%;
        min-width: 11rem;
        max-width: 20rem;
        justify-content: space-evenly;
    }

    .social-icon-wraper > a{
        font-size: 1.4rem;
    }

    /*********** HERO *************/

    .heading-container {
        max-width: 18em;
        /* margin: 20% auto 12%; */
        margin: 0 auto 8%;
        text-align: left;
        font-family: 'Sarabun', sans-serif;
        font-weight: 300;
    }

    .heading-container > p{
        font-size: 3.5rem;
        margin: 3px;
    }
    
    .line2-heading{
        padding-left: 1.9rem;
        margin-bottom: 0px;
    }
    
    .profesion{
        font-size: 1.3rem;
        margin-top: 5px;
        margin-bottom: 3px;
        margin-left: 1.5rem;
    }
    
    .subject{
        font-size: 0.900rem;
        margin-top: 0px;
        margin-left: 10.2rem;
    }

    .separator{
     position: relative;
     margin-top: 0;
     left: 6.1rem;
     height: 1px;
      width: 9rem;
      background-color: #EAE3D2;
    }

    /******** QUOTE SECTION **********/

    .quote-container{
        margin: 0 auto 2rem;
        max-width: 18em;
        max-height: 100px;
        text-align: center;
        color:rgb(254, 250, 240);
    }

    .quote-container > h2{
        font-family: 'Fasthand', cursive;
        font-size: 1.5rem;
        font-weight: 300;
    }

    /********** NAVIATION BAR **********/

    .navbar-item-box{
       height: 2.6rem;
    }

    .navbar-item-box p{
        padding: 0.4rem;
        font-size: 1em;
        color: rgb(254, 250, 240);
        letter-spacing: 2px;

    }

    #main-navbar-container #me-link,
    #main-navbar-container #background-link{
        display: none;
    }
    
    /********** NAVBAR SECTIONS *******/

    .secondary-navbar-wraper .navbar-item-box{
        height: 2.6rem;
        justify-content: center;
     }

     .secondary-navbar-wraper .navbar-item-box p{
        padding: 7px;
        font-size: 1em;
        letter-spacing: 1px;

    }

    /*********** ME SECTION ***********/
    .section-container{
        padding: 0 0 4rem;
    }

    .me-container > .section-content-container{
        max-width: 470px;
    }

    .me-container > .section-content-container p{
        font-size: 1.1em;
    }

    .me-container > .section-content-container h6{
        font-size: 1.1em;
        margin: 3.2rem 1.2rem 2rem;
    }

    /********* HOBBIES ****/

    .hobbies-title-container{
        max-width: 470px;
        margin: 3rem auto auto;
        padding-left: 1.4rem;
    }

    .hobbies-title{
        margin-bottom: 3rem;
    }
 

 .hobbies-container .row {
    margin-bottom: 2rem;
 }

 #new-hobbie{
    font-size: 0.700rem;
 }

 .hobbies-container .row .personality-quote{
    font-size: 1em;

}

/************ PROJECTS SECTION */

.projects-container .row{
    margin: 0 auto ;

}

.project-card{
    color: #1c3879;
    max-width: 410px;
    margin-bottom: 7rem;
    min-height: 500px;    
}

.screen{
   width: 280px;
   height: 170px;
   border: solid 6px rgb(70, 70, 70);
   margin: 2rem auto auto;
   border-radius: 5px;
}

.screen-base{
    border-radius: 5px;
    width: 270px;
    height: 8px;
    background-color: rgb(70, 70, 70);
    margin: 0.5rem auto;
}


.project-card-head h6{
    font-size: 1.3em;
    margin-bottom: 1rem;
}

.project-description{
    padding: 10% 0;
    font-weight: 400;
}

.project-description small{
    color: #1c3879;

    font-size: 1.1em;
}

/******  BACKGROUND SECTION */

.background-container{
    max-width: 410px;
}

.background-heading{
    margin-bottom: 2rem;
}

.course-container ul li{
    line-height: 1.6rem;
    font-size: 1.1rem;
}

.hospitality-container-wraper{
    border-right: solid 2px #E6CBA8;
    padding-right: 10px;
 }

.hospitality-container-wraper > p{
    font-size: 1.1rem;
    line-height: 1.6rem;
}

ul{
    padding-left: 7px;
}

.dot{
    width: 4px;
    height: 4px;
    margin: auto 3px;
    background-color: #a3a3a3;
}

/********* CONTACT SECTION ***/

#contact-icon-2{
    position: fixed;
    z-index: 2;
    bottom: 2rem;
    right: 2rem;
    display: none;
}

   .contact-wraper{
    position: absolute;
    top: 10%;
    left: 5%;
    height: 80%;
    width: 90%;
    border-radius: 2%;
}

.close-container {
    text-align: end;
    padding-right: 1.8rem;
}

.contact-container{
max-width: 410px;
padding: 3rem 1.5rem;
min-height: 100vh;
}

.contact-container h3{
    font-size: 1.5rem;
}


#contact-form .form-control{
    margin-bottom: 0.7rem;
    font-size: 0.950rem;
}

.form-control::placeholder{
    font-size: 0.900rem;
}

textarea{
    height: 80px;
}
}


/*************************************** TABLET SCREEN SIZE*/

@media only screen and (min-width: 600px) {

    .social-wraper{
        width: 100%;
        /* margin: 7% auto auto; */
        min-height: 100%;
        height: 0;

    }

    .social-container{
        display: flex;
        margin: 0 auto;
        flex-direction: row;
        width: 75%;
        min-width: 12rem;
        max-width: 20rem;
        justify-content: space-evenly;
    }

    .social-icon-wraper > a{
        font-size: 1.4em;
    }

    /*********** HERO *************/

    /* #hero-wrapper{
        min-height: 685px;
        max-height: 70vh;
    } */

    .heading-container{
        max-width: 20em;
        /* margin: 3em auto 2em;
        text-align: left; */
        /* margin: 2.5em auto 3em; */
        margin: 23% auto 6%;
        font-family: 'Sarabun', sans-serif;
        font-weight: 300;
    }

    .heading-container > p{
        font-size: 4rem;
        margin: 3px;
    }
    
    .line2-heading{
        padding-left: 1.8rem;
        margin-bottom: 0px;
    }
    
    .profesion{
        font-size: 1.4rem;
        margin-top: 5px;
        margin-bottom: 3px;
        margin-left: 2.8rem;
    }

    .subject{
        font-size: 0.900rem;
        margin-top: 0px;
        margin-left: 12.5rem;
    }

    .separator{
     position: relative;
     margin-top: 0;
     left: 7rem;
      height: 1px;
      width: 10.3rem;
      background-color: rgb(254, 250, 240);
      ;
    }

    /******** QUOTE SECTION **********/
 
    .quote-container{
        margin: 1rem auto 2rem;
        max-width: 18rem;
        max-height: 100px;
        text-align: center;
        color: rgb(254, 250, 240);

    }

    .quote-container > h2{
        font-family: 'Fasthand', cursive;
        font-size: 1.5em;
        font-weight: 300;
    }

    /********** NAVIATION BAR **********/

    .navbar-container, .main-navbar-container{
        max-width: 750px;
    }
    
    .navbar-item-box{
       height: 2.4rem;
    }

    .navbar-item-box p{
        padding: 5px;
        font-size: 1.050em;
        color: rgb(254, 250, 240);

    }

    .main-navbar-container > .me-link, .main-navbar-container > .background-link{
        display: none;
    }
    
    /********** NAVBAR SECTIONS *******/
    
    .secondary-navbar-wraper .navbar-item-box{
        height: 2.4rem;
     }

     .secondary-navbar-wraper .navbar-item-box p{
        padding: 5px;
        font-size: 1.050em;
        letter-spacing: 2px;

    }

    /*********** ME SECTION ***********/

    .section-wraper{
        position: relative;
        width: 100%;
        margin: 0 auto;
        background-color: #a3a3a3;
    }
    
    .section-container{
        padding: 0 0 4rem;
    }

    .me-container, .hobbies-container{
        max-width: 630px;
    }

    .me-container > .section-content-container{
        max-width: 470px;
    }

    .me-container > .section-content-container p{
        font-size: 1.050rem;
        letter-spacing: 1px;
    }

    .me-container > .section-content-container h6{
        font-size: 1.050rem;
        margin: 3.2rem 1.8rem 2rem;
    }
    

    /********* HOBBIES ****/

    .hobbies-title-container{
        max-width: 470px;
        margin: 3rem auto auto;
        padding-left: 1.7rem;
    }

    .hobbies-title{
        margin-bottom: 3rem;
        font-size: 1.6rem;
    }

 .hobbies-container .row {
    margin-bottom: 2rem;
 }

 .hobbies-container .row .personality-quote{
    font-size: 1.1rem;
}

/************ PROJECTS SECTION */

.project-card{
    max-width: 27rem;
    margin-bottom: 8rem;
}

.screen{
    width: 350px;
    height: 240px;
    border: solid 8px rgb(70, 70, 70);
    margin: 2rem auto auto;
 }

 .screen-base{
    border-radius: 5px;
    width: 330px;
    height: 10px;
    background-color: rgb(70, 70, 70);
    margin: 0.5rem auto;
}

.project-tech{
   max-width: 100%;
}

.project-card h6{
    margin-bottom: 1.1rem;
    font-size:1.2rem;
}

.project-description{
    letter-spacing: 1px;
    margin-top: 1rem;
}

.project-description small{
    font-size: 1.2em;
}

/******  BACKGROUND SECTION */

.background-container{
    max-width: 450px;
}

.course-container{
    font-size: 0.975rem;
}

.hospitality-container-wraper > p{
    font-size: 0.975rem;
    line-height: 1.4rem;
}

ul{
    padding-left: 7px;
}

/********* CONTACT SECTION ***/

 #contact-icon-1 img, #contact-icon-2 img{
    width: 100%;
    height: 100%;
   }



.contact-wraper {
    position: absolute;
    top: 10%;
    left: 15%;
    height: 80%;
    width: 70%;
    border-radius: 2%;
}

.contact-container{
max-width: 410px;
padding: 3rem 1.5rem;
min-height: 100vh;
}

.contact-container h3{
    font-size: 1.6rem;
}

#contact-form .form-control{
    margin-bottom: 0.7rem;
    font-size: 1rem;
}

.form-control::placeholder{
    font-size: 0.950rem;
}

}


/************************************** DESKTOP SCREEN SIZE */
@media only screen and (min-width: 900px) {

    .social-wraper{
        width: 100%;
        padding-top: 2%;
        height: 9vh;

    }

    .social-container{
        display: flex;
        margin: 0 auto;
        flex-direction: row;
        width: 60%;
        min-width: 11rem;
        max-width: 23rem;
        justify-content: space-evenly;
        vertical-align: middle;
    }

    .social-icon-wraper > a {
        font-size: 1.3rem;
    }

    .social-icon-wraper{
        width: 40px;
        height: 40px;
        border: solid 1px #1C3879;  
       border-radius: 150%;  
       text-align: center;
    }
    
    .social-icon-wraper:hover{
            border: solid 1px #1C3879;  
           border-radius: 150%;  
            animation-name: circle;
            animation-duration: 0.4s;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in-out;
    }

    .project-tech-large .social-icon-wraper{
        border-color: rgb(254, 250, 240);
    }
    .project-tech-large .social-icon-wraper:hover{
        border-color: rgb(254, 250, 240);
    }
    
    @keyframes circle {
      
        to {
        border: solid 1px rgb(254, 250, 240); 
    
        }
    
    }
    
    /*********** HEADING *************/
    
    .heading-wraper .container-fluid{
        max-width: 37rem;
    }

    .heading-container {
        max-width: 18em;
        margin: 8% auto 5%;
        /* margin: 20% auto 8%; */

        text-align: left;
        font-family: 'Sarabun', sans-serif;
        font-weight: 300;
    }

    .heading-container > p{
        font-size: 3.6rem;
        margin: 3px;
    }
    
    .img-container{
        width: 230px;
        height: 160px;
        border-radius: 50%;
        background-image: url("https://res.cloudinary.com/dthlibbj7/image/upload/v1668343688/FORTALEZA_HOHENSALZBURG_20_iiqcmp.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: none;
    }
    
    .line2-heading{
        padding-left: 1.7rem;
        margin-bottom: 0px;
    }
    
    .profesion{
        font-size: 1rem;
        margin-top: 5px;
        margin-bottom: 3px;
        margin-left: 5.5rem;
    }
    
    .subject{
        font-size: 0.700rem;
        margin-top: 0px;
        margin-left: 12.2rem;
    }

    .separator{
     position: relative;
     margin-top: 0;
     left:120px;
      height: 1px;
      width: 10rem;
      background-color: rgb(254, 250, 240);
    }

    .img-container{
        margin: 7rem auto auto;
        text-align: center;
    }

    /******** QUOTE SECTION **********/

    .quote-container{
        margin: 0 auto 2rem;
        max-width: 20em;
        max-height: 100px;
        text-align: center;
        color: rgb(254, 250, 240);
    }

    .quote-container > h2{
        font-family: 'Fasthand', cursive;
        font-size: 1.5rem;
        font-weight: 300;
    }

/******************* MAIN NAVBAR*/

    .navbar-container, .main-navbar-container{
         padding: 5px 3px 0; 
        max-width: 880px;
    }
    
    .navbar-item-box{
       height: 2.5rem;
       border-top: solid 1px rgb(254, 250, 240); 
       border-right: solid 1px rgb(254, 250, 240);
       border-left: solid 1px rgb(254, 250, 240);
    }

    .navbar-item-box p{
        padding: 8px;
        font-size: 1em;
        font-weight: 400;
        color: rgb(254, 250, 240);
    }

    #main-navbar #main-navbar-container #me-link, 
    #main-navbar #main-navbar-container #background-link{
        display: block;
    }

    /********** SECONDARY NAVBAR *******/
    
    .secondary-navbar-wraper .navbar-item-box{
        height: 2.5rem;
     }

     .secondary-navbar-wraper .navbar-item-box p{
        padding: 8px;
        font-size: 1em;
    }

    /************ ME SECTION */

    .section-container{
        padding: 0 0 6rem;
    }

    .me-container, .hobbies-container{
        max-width: 730px;
    }

    .me-container > .section-content-container{
        max-width: 500px;
    }

    .me-container > .section-content-container p{
        font-size: 1.1rem;
        line-height: 2rem;
        letter-spacing: 1px;
    }

    .me-container > .section-content-container h6{
        line-height: 2rem;
        font-size: 1.1rem;
        margin: 3.5rem 1.8rem 2rem;
    }
    
    /********* HOBBIES ****/

    .hobbies-title-container{
        max-width: 470px;
        margin: 3rem auto 2.8rem;
        padding-left: 0;
    }

    .hobbies-title{
        font-size: 1.6rem;
    }

 .hobbies-container .row {
    margin-bottom: 2.8rem;
 }

 .hobbies-container img{
    width: 65px;
    height: 65px;
 }

 .hobbies-container .row .personality-quote{
      font-size: 1.1rem;
}

/************ PROJECTS SECTION */

.projects-wraper{
    margin-top: 8rem;
}

.project-card{
    max-width: 900px;
    margin-bottom: 8rem;
    color:#1C3879;
}

.screen{
    width: 390px;
    height: 250px;
    border: solid 8px rgb(70, 70, 70);
    margin: 2rem auto auto;
    border-radius: 5px;
 }

 .screen-base{
    width: 370px;
}

.project-tech-large{
    margin: 3rem auto 0;
    display: flex;
    justify-content: space-evenly;
    max-width: 30rem;
 }

.project-tech-large img{
   opacity: 0.6; 
   width: 32px;
    height: 32px;
}

.project-tech-column{
    display: flex;
   justify-content: space-evenly;
 }

.project-card h6{
    margin-bottom: 2rem;
    font-size: 1.4rem;
    font-weight: 600;
}


.project-description{
    letter-spacing: 0.3px;
    margin-top: 4rem;
    font-weight: 400;
    line-height: normal;
}

small{
    font-size: 1rem;
}

/******  BACKGROUND SECTION */

.background-container{
    max-width: 800px;
}

.course-container{
    font-size: 1rem;
}

.course-container ul li{
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.course-container ul span{
   font-weight: 400;
}

.course-container a{
    font-weight: 400;
}

.hospitality-container-wraper > p{
    font-size: 1.2rem;
    line-height: 1.8rem;
}

ul{
    padding-left: 7px;
}

.dot{
    width: 5px;
    height: 5px;
    margin: auto 3px;
    background-color: #a3a3a3;
    border-radius: 50%;
}

/********* CONTACT SECTION ***/

#contact-icon-1 img, #contact-icon-2 img{
    width: 100%;
    height: 100%;
   }

#contact-icon-2{
    position: fixed;
    z-index: 2;
    bottom: 2rem;
    right: 8rem;
    display: none;
}

.contact-wraper{
    position: absolute;
    top: 5%;
    left: 15%;
    height: 90%;
    width: 70%;
    border-radius: 2%;
}

.contact-wraper img{
    width: 90px;
    height: 90px;
}

.contact-container{
max-width: 25rem;
padding: 1rem 1.5rem;
}

.contact-container h3{
    font-size: 1.9rem;
}


#contact-form input{
    height: 2.5rem;
}

#contact-form textarea{
    height: 7rem;
}

#contact-form .form-control{
    margin-bottom: 0.8rem;
    font-size: 1rem;
}

.form-control::placeholder{
    font-size: 0.950rem;
}

#contact-form button{
    height: 2.5rem;
}

}

/********************************************* LARGE SCREEN SIZE*/
@media (min-width: 1600px) {

    html {
        font-size: clamp(100.75%, 2.2vw, 130%);
        /** this says:
           set the minimun font size to around 16px
           and set the maximun font size to 22px */
      }
    
    .social-wraper{
        width: 100%;
        height: 8vh;
    }

    .social-container{
        display: flex;
        margin: 0 auto;
        padding-top: 2%;
        flex-direction: row;
        width: 100%;
        min-width: 20rem;
        max-width: 30%;
        justify-content: space-evenly;
    }

    .social-icon-wraper > a {
        font-size: 115%;
    }


    /*********** HERO *************/
    
    .heading-wraper .container-fluid{
        max-width: 34rem;
    }

    .heading-container {
        max-width: 55%;
        margin: 8% auto 10%;
        text-align: left;
        font-family: 'Sarabun', sans-serif;
        font-weight: 300;
    }

    .heading-container > p{
        font-size: 3.4rem;
        margin: 3px;
    }
   
    .img-container{
        margin: 8rem auto auto;
        text-align: center;
        width: 65%;
        height: 40%;
        border-radius: 50%;
        background-image: url("https://res.cloudinary.com/dthlibbj7/image/upload/v1668343688/FORTALEZA_HOHENSALZBURG_20_iiqcmp.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: none;
    }
    
    .line2-heading{
        padding-left: 1.7rem;
        margin-bottom: 0px;
    }
    
    .profesion{
        font-size: 1rem;
        margin-top: 5px;
        margin-bottom: 3px;
        margin-left: 4.4rem;
    }

    .subject{
        font-size: 0.850em;
        margin-top: 0px;
        margin-left: 9.5rem;
        padding-left: 0.8rem;
    }

    .separator{
     position: relative;
     margin-top: 0;
     left:125px;
      height: 1px;
      width: 10rem;
      background-color: rgb(254, 250, 240);
    }
    

    /******** QUOTE SECTION **********/

    .quote-container{
        margin: 0 auto 2rem;
        max-width: 20em;
        max-height: 100px;
        text-align: center;
        color: rgb(254, 250, 240);
    }

    .quote-container > h2{
        font-family: 'Fasthand', cursive;
        font-size: 1.4rem;
        font-weight: 300;
    }

    /******************* MAIN NAVBAR*/
    .navbar-container, .main-navbar-container{
        padding: 5px 3px 0; 
        width: 100%;
        max-width: 50rem;
    }
    
    .navbar-item-box{
       height: 2.6rem;
    }

    .navbar-item-box p{
        padding: 0.5rem;
        font-size: 110%;
    }

    
    /********** SECONDARY NAVBAR *******/
    .secondary-navbar-wraper{
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0 auto 8rem;
    }
    
    .secondary-navbar-wraper .navbar-item-box{
        height: 2.8rem;
     }

     .secondary-navbar-wraper .navbar-item-box p{
        padding: 0.4rem;
        font-size: 1.3em;
    }

    /************ ME SECTION */

    .section-container{
        padding: 0;
    }

    .me-container, .hobbies-container{
        max-width: 870px;
    }

    .me-container > .section-content-container{
        max-width: inherit
    }

    .me-container > .section-content-container p{
        font-size: 1.3em;
        line-height: 2rem;
        letter-spacing: 1px;
    }

    .me-container > .section-content-container h6{
        line-height: 2rem;
        font-size: 1.3rem;
        margin: 3.5rem 1.8rem 2rem;
    }
    
    /********* HOBBIES ****/

    .hobbies-title-container{
        max-width: inherit;
        margin: 2.5rem auto;
        padding-left: 0;
    }

    .hobbies-title{
        font-size: 1.8em;
    }

 .hobbies-container .row {
    margin-bottom: 2.8rem;
 }

 .hobbies-container img{
    width: 3.6rem;
    height: 3.6rem;
 }

 .hobbies-container .row .personality-quote{
    font-size: 1.3rem;
}

/************ PROJECTS SECTION */

.projects-wraper{
    margin-top: 6rem;    
    max-width: 52rem;
}

.projects-wraper-container{
    max-width: 90rem;
}

.project-card{
    max-width: 59rem;
    margin-bottom: 7rem;
    color:#1C3879;
}

.project-tech{
    max-width: 17rem;
}

.project-tech-column{
    margin-top: 3rem;
    display: flex;
   justify-content: space-evenly;
 }

 .project-tech-large {
    margin: 4rem auto 0;
}

.project-tech-large img{
    width: 40px;
    height: 40px;
    margin: 0 auto;
 }

.project-card h6{
    margin-bottom: 1.5rem;
    font-weight: 600;
}

.project-description{
    letter-spacing: 1px;
    margin-top: 3rem;
}

.screen{
    width: 450px;
    height: 270px;
    border: solid 8px rgb(70, 70, 70);
    margin: 2rem auto auto;
    border-radius: 5px;
 }

 .screen-base{
    width: 370px;
}

/******  BACKGROUND SECTION */

.background-container{
    max-width: 46rem;
    padding-bottom: 6rem;
}

#background-container h2{
   margin-bottom: 5rem;
}

.course-container{
    font-size: 1.1em;
    margin: 2.5rem auto 2.5rem;
    line-height: 1.6rem;
}

.course-container ul li{
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.course-container ul span{
   font-weight: 400;
}

.course-container a{
    font-weight: 400;
}

.hospitality-container-wraper > p{
    margin-top: 3rem;
    font-size: 1.3rem;
    line-height: 1.8rem;
}

ul{
    padding-left: 7px;
}

.dot{
    width: 6px;
    height: 6px;
    margin: 2rem 10px;
    border-radius: 50%;
    background-color: #a3a3a3;
}

/********* CONTACT SECTION ***/

#contact-icon-1 img, #contact-icon-2 img{
    width: 105%;
    height: 105%;
   }

#contact-icon-2{
    position: fixed;
    z-index: 2;
    bottom: 2rem;
    right: 8rem;
    display: none;
}

.contact-wraper {
    position: absolute;
    top: 5%;
    left: 15%;
    height: 90%;
    width: 70%;
    border-radius: 2%;
}

.contact-wraper img{
    width: 110px;
    height:110px;
}

.contact-container{
max-width: 25rem;
padding: 3rem 1.5rem;
min-height: 100vh;
}

.contact-container h3{
    font-size: 1.9rem;
}

#contact-form input{
    height: 2.5rem;
}

#contact-form textarea{
    height: 7rem;
}

#contact-form .form-control{
    margin-bottom: 0.8rem;
    font-size: 1rem;
}

.form-control::placeholder{
    font-size: 0.950rem;
}

#contact-form button{
    height: 2.5rem;
}

.feedback-box{
    padding-top: 1rem;
}

}
